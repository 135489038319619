import { Fab } from '@mui/material';
import styled from 'styled-components';

export const StyledFab = styled(Fab)`
  color: #fff;
  &:hover {
    color: #fff;
  }
  & .MuiFab-label {
    color: #fff;
  }
`;
