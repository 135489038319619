import { orange, red } from '@mui/material/colors';
import styled, { css } from 'styled-components';

import { Environment } from '@playq/octopus-common';

import { ThemeMode } from '/common/models';
import { ThemeProvider } from '/common';

import { ILabelThemedProps, LabelThemedColor } from './types';

export const ORANGE_LABEL_THEMED_COLOR: string = orange[500];
export const RED_LABEL_THEMED_COLOR: string = red[500];

export const LabelStyled = styled.span<ILabelThemedProps>`
  --main-color: ${(props) => {
    const {
      env,
      color,
      theme: { palette },
    } = props;
    if (env === Environment.Sandbox) {
      return ORANGE_LABEL_THEMED_COLOR;
    }

    if (env === Environment.Live) {
      return RED_LABEL_THEMED_COLOR;
    }

    if (color === LabelThemedColor.Grey) {
      return ThemeProvider.getAppropriateStyles(palette.mode as ThemeMode, palette[color][400], palette[color][700]);
    }

    if (color !== undefined && palette[color] !== undefined) {
      return palette[color].main;
    }
    return props.theme.palette.primary.main;
  }};
  background-color: var(--main-color);
  color: ${(props) => props.theme.palette.primary.contrastText};
  ${(props) =>
    props.outline &&
    css`
      background-color: transparent;
      border: 1px solid var(--main-color);
      color: var(--main-color);
    `};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  margin-left: ${(props) => (props.withoutMargins?.left === true ? 'inherit' : '5px')};
  margin-right: ${(props) => (props.withoutMargins?.right === false ? '5px' : 'inherit')};
  white-space: nowrap;
`;
