/* eslint-disable @typescript-eslint/restrict-template-expressions */

import styled from 'styled-components';

import { LabelThemed } from '/shared/LabelThemed';
import { IThemeDependentStyledComponentProps } from '/common/models';

export const StatusButton = styled.button`
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  outline: none;
  padding-left: 0;
`;

export const EnvLabel = styled(LabelThemed)<IThemeDependentStyledComponentProps>`
  margin: 0;
  font-size: 13px;
  padding: 2px 4px;

  ${(props) =>
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    !props.env &&
    `
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      color: ${props.$themePalette?.text.primary};
      font-weight: 400;
  `}
`;
