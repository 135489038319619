import { ChangeEvent, FC } from 'react';
import { Switch, ListItem, ListItemText } from '@mui/material';
import classNames from 'classnames';
import { Schema } from 'yup';
import styled from 'styled-components';
import { PhotoCamera } from '@mui/icons-material';

import { Market as MarketsEnum } from '@playq/services-shared';

import { TextFieldValidator } from '/shared/TextFieldValidator';

import { useStyles } from './styles';

const PhotoCameraIcon = styled(PhotoCamera)`
  width: calc(100% - 25%);
  height: calc(100% - 25%);
` as typeof PhotoCamera;

export interface IMarketProps {
  market: MarketsEnum;
  marketId: string;
  imageUrl?: string;
  imageSelected?: boolean;
  selected: boolean;
  validationSchema: Schema<string> | null;
  readonly: boolean;
  changeMarket: (toggle: boolean, id?: string) => void;
  selectMarketImage?: (market?: MarketsEnum) => void;
  onIdChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Market: FC<IMarketProps> = (props) => {
  const {
    market,
    marketId,
    imageUrl,
    imageSelected,
    selected,
    readonly,
    validationSchema,
    selectMarketImage,
    changeMarket,
    onIdChange,
  } = props;

  const classes = useStyles();

  const iconClasses = imageSelected ? classes.marketIconSelected : imageUrl ? classes.marketIconEnabled : '';

  const handleSelectMarketImage = () => {
    if (!imageUrl || imageSelected || !selectMarketImage) {
      return;
    }
    selectMarketImage(market);
  };

  const toggleMarket = () => changeMarket(true);

  return (
    <ListItem className={classes.market}>
      <div className={classes.marketIconSwitch}>
        <div
          className={classNames(classes.marketIcon, iconClasses)}
          onClick={imageUrl ? handleSelectMarketImage : undefined}
        >
          {imageUrl ? <img className={classes.image} src={imageUrl} alt='app-market-icon' /> : <PhotoCameraIcon />}
        </div>
        <Switch data-testid={`${market}-switch`} onChange={toggleMarket} checked={selected} disabled={readonly} />
        <ListItemText primary={market} />
      </div>
      {selected && (
        <TextFieldValidator
          inputProps={{ 'data-testid': `${market}_id` }}
          disabled={readonly}
          required={true}
          label={`${market} id...`}
          value={marketId}
          onChange={onIdChange}
          schema={validationSchema}
        />
      )}
    </ListItem>
  );
};
